
// @ts-nocheck


export const localeCodes =  [
  "en",
  "en_AU",
  "pt_BR",
  "zh_CN",
  "cs_CZ",
  "da_DK",
  "de_DE",
  "es_ES",
  "fr_FR",
  "it_IT",
  "ja_JP",
  "es_MX",
  "nl_NL",
  "no_NO",
  "pl_PL",
  "pt_PT",
  "sv_SE",
  "en_AE",
  "en_SG",
  "en_GB"
]

export const localeLoaders = {
  "en": [],
  "en_AU": [],
  "pt_BR": [],
  "zh_CN": [],
  "cs_CZ": [],
  "da_DK": [],
  "de_DE": [],
  "es_ES": [],
  "fr_FR": [],
  "it_IT": [],
  "ja_JP": [],
  "es_MX": [],
  "nl_NL": [],
  "no_NO": [],
  "pl_PL": [],
  "pt_PT": [],
  "sv_SE": [],
  "en_AE": [],
  "en_SG": [],
  "en_GB": []
}

export const vueI18nConfigs = [
  () => import("../../core/i18n.config.ts?hash=7566eedd&config=1" /* webpackChunkName: "i18n_config_7566eedd" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "Global",
      "language": "en"
    },
    {
      "code": "en_AU",
      "name": "Australia",
      "language": "en-AU"
    },
    {
      "code": "pt_BR",
      "name": "Brasil",
      "language": "pt-BR"
    },
    {
      "code": "zh_CN",
      "name": "中国",
      "language": "zh-CN"
    },
    {
      "code": "cs_CZ",
      "name": "Česká republika",
      "language": "cs-CZ"
    },
    {
      "code": "da_DK",
      "name": "Danmark",
      "language": "da-DK"
    },
    {
      "code": "de_DE",
      "name": "Deutschland",
      "language": "de-DE"
    },
    {
      "code": "es_ES",
      "name": "España",
      "language": "es-ES"
    },
    {
      "code": "fr_FR",
      "name": "France",
      "language": "fr-FR"
    },
    {
      "code": "it_IT",
      "name": "Italia",
      "language": "it-IT"
    },
    {
      "code": "ja_JP",
      "name": "日本",
      "language": "ja-JP"
    },
    {
      "code": "es_MX",
      "name": "México",
      "language": "es-MX"
    },
    {
      "code": "nl_NL",
      "name": "Nederland",
      "language": "nl-NL"
    },
    {
      "code": "no_NO",
      "name": "Norge",
      "language": "no-NO"
    },
    {
      "code": "pl_PL",
      "name": "Polska",
      "language": "pl-PL"
    },
    {
      "code": "pt_PT",
      "name": "Portugal",
      "language": "pt-PT"
    },
    {
      "code": "sv_SE",
      "name": "Sverige",
      "language": "sv-SE"
    },
    {
      "code": "en_AE",
      "name": "United Arab Emirates",
      "language": "en-AE"
    },
    {
      "code": "en_SG",
      "name": "Singapore",
      "language": "en-SG"
    },
    {
      "code": "en_GB",
      "name": "United Kingdom",
      "language": "en-GB"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://investors.adyen.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "events/[slug]": {
      "en": "/events/[slug]",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "events/index": {
      "en": "/events",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "financials/[slug]": {
      "en": "/financials/[slug]",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "financials/index": {
      "en": "/financials",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "financials/trading-updates": {
      "en": "/financials/trading-updates",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "general-meetings/index": {
      "en": "/general-meetings",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "governance/index": {
      "en": "/governance",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    },
    "index": {
      "en": "/",
      "en-AU": false,
      "en_AU": false,
      "pt-BR": false,
      "pt_BR": false,
      "zh-CN": false,
      "zh_CN": false,
      "cs-CZ": false,
      "cs_CZ": false,
      "da-DK": false,
      "da_DK": false,
      "de-DE": false,
      "de_DE": false,
      "es-ES": false,
      "es_ES": false,
      "fr-FR": false,
      "fr_FR": false,
      "it-IT": false,
      "it_IT": false,
      "ja-JP": false,
      "ja_JP": false,
      "es-MX": false,
      "es_MX": false,
      "nl-NL": false,
      "nl_NL": false,
      "no-NO": false,
      "no_NO": false,
      "pl-PL": false,
      "pl_PL": false,
      "pt-PT": false,
      "pt_PT": false,
      "sv-SE": false,
      "sv_SE": false,
      "en-AE": false,
      "en_AE": false,
      "en-SG": false,
      "en_SG": false,
      "en-GB": false,
      "en_GB": false
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "Global",
    "language": "en",
    "files": []
  },
  {
    "code": "en_AU",
    "name": "Australia",
    "language": "en-AU",
    "files": []
  },
  {
    "code": "pt_BR",
    "name": "Brasil",
    "language": "pt-BR",
    "files": []
  },
  {
    "code": "zh_CN",
    "name": "中国",
    "language": "zh-CN",
    "files": []
  },
  {
    "code": "cs_CZ",
    "name": "Česká republika",
    "language": "cs-CZ",
    "files": []
  },
  {
    "code": "da_DK",
    "name": "Danmark",
    "language": "da-DK",
    "files": []
  },
  {
    "code": "de_DE",
    "name": "Deutschland",
    "language": "de-DE",
    "files": []
  },
  {
    "code": "es_ES",
    "name": "España",
    "language": "es-ES",
    "files": []
  },
  {
    "code": "fr_FR",
    "name": "France",
    "language": "fr-FR",
    "files": []
  },
  {
    "code": "it_IT",
    "name": "Italia",
    "language": "it-IT",
    "files": []
  },
  {
    "code": "ja_JP",
    "name": "日本",
    "language": "ja-JP",
    "files": []
  },
  {
    "code": "es_MX",
    "name": "México",
    "language": "es-MX",
    "files": []
  },
  {
    "code": "nl_NL",
    "name": "Nederland",
    "language": "nl-NL",
    "files": []
  },
  {
    "code": "no_NO",
    "name": "Norge",
    "language": "no-NO",
    "files": []
  },
  {
    "code": "pl_PL",
    "name": "Polska",
    "language": "pl-PL",
    "files": []
  },
  {
    "code": "pt_PT",
    "name": "Portugal",
    "language": "pt-PT",
    "files": []
  },
  {
    "code": "sv_SE",
    "name": "Sverige",
    "language": "sv-SE",
    "files": []
  },
  {
    "code": "en_AE",
    "name": "United Arab Emirates",
    "language": "en-AE",
    "files": []
  },
  {
    "code": "en_SG",
    "name": "Singapore",
    "language": "en-SG",
    "files": []
  },
  {
    "code": "en_GB",
    "name": "United Kingdom",
    "language": "en-GB",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
