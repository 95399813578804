import { default as _91slug_93VDEzY0Ld46Meta } from "/opt/build/repo/website/investor-relations/pages/events/[slug].vue?macro=true";
import { default as indexQwxthw0abUMeta } from "/opt/build/repo/website/investor-relations/pages/events/index.vue?macro=true";
import { default as _91slug_93ist3irCh5ZMeta } from "/opt/build/repo/website/investor-relations/pages/financials/[slug].vue?macro=true";
import { default as indexItDAU26AhpMeta } from "/opt/build/repo/website/investor-relations/pages/financials/index.vue?macro=true";
import { default as trading_45updatese4OMkluVOCMeta } from "/opt/build/repo/website/investor-relations/pages/financials/trading-updates.vue?macro=true";
import { default as indexpju95BoV4OMeta } from "/opt/build/repo/website/investor-relations/pages/general-meetings/index.vue?macro=true";
import { default as indexbY1MrNhtLdMeta } from "/opt/build/repo/website/investor-relations/pages/governance/index.vue?macro=true";
import { default as indexyBzkNuPtigMeta } from "/opt/build/repo/website/investor-relations/pages/index.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/opt/build/repo/website/investor-relations/pages/events/[slug].vue")
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/opt/build/repo/website/investor-relations/pages/events/index.vue")
  },
  {
    name: "financials-slug___en",
    path: "/financials/:slug()",
    component: () => import("/opt/build/repo/website/investor-relations/pages/financials/[slug].vue")
  },
  {
    name: "financials___en",
    path: "/financials",
    component: () => import("/opt/build/repo/website/investor-relations/pages/financials/index.vue")
  },
  {
    name: "financials-trading-updates___en",
    path: "/financials/trading-updates",
    component: () => import("/opt/build/repo/website/investor-relations/pages/financials/trading-updates.vue")
  },
  {
    name: "general-meetings___en",
    path: "/general-meetings",
    component: () => import("/opt/build/repo/website/investor-relations/pages/general-meetings/index.vue")
  },
  {
    name: "governance___en",
    path: "/governance",
    component: () => import("/opt/build/repo/website/investor-relations/pages/governance/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/build/repo/website/investor-relations/pages/index.vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]